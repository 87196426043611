// @ts-ignore
$ = window.$;

class PasswordValidator {

    $document = $(document);
    $registrationForm: JQuery = $("form#user_registration_form, form#reset_password_form, form#change_password_form").not(".change-email");
    $firstName: JQuery = this.$registrationForm.find("#user_first_name");
    $lastName: JQuery = this.$registrationForm.find("#user_last_name");
    $submitButton: JQuery = this.$registrationForm.find("input[type='submit']");
    $email: JQuery = this.$registrationForm.find('#user_email');
    $oldPassword: JQuery = this.$registrationForm.find('#user_current_password');
    $password: JQuery = this.$registrationForm.find("input[name='user[password]']");
    $password_confirmation: JQuery = this.$registrationForm.find('#user_password_confirmation');
    registrationFields = [this.$password];
    resetFields = [this.$password];

    $noEmailMessage = this.$registrationForm.find('.no-email-valid-password-message');
    $numberMessage = this.$registrationForm.find('.number-valid-password-message')
    $caseMessage = this.$registrationForm.find('.uppercase-lowercase-valid-password-message');
    $lengthMessage = this.$registrationForm.find('.length-valid-password-message');

    caseValidator: RegExp = /(?=.*[a-z])(?=.*[A-Z])/;
    characterValidator: RegExp = /[!@#$%^&()*]/;
    numberValidator: RegExp = /[0-9]/;
    passwordMin: number = this.$lengthMessage.data('min');
    //lowercase and uppercase, number, symbol, length
    passwordValidator: RegExp = new RegExp(`(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{${this.passwordMin},}`);
    emailValidator: RegExp = /^([^@\s]+)@[^@\s]+\.[^@\s]+$/;

    emailOrNameValidator () {
        return new RegExp(`\b${this.$firstName.val()}|\b${this.$lastName.val()}|\b${this.$email.val()}`)
    };

    init() {
        this.$submitButton.attr('disabled', 'true');
        $.extend(this.$email, {
            validator: this.emailValidator,
            validate: function () {
                if (this.val != '') {
                    return this.validator.test(this.val());
                } else return false
            }
        });
        $.extend(this.$password, {
            validator: this.passwordValidator,
            validate: function () {
                if (this.val != '') {
                    return this.validator.test(this.val());
                } else return false
            }
        });
        $.extend(this.$password_confirmation, {
            validate: function (field) {
                let value: string = this.val();
                let fieldVal: string = field.val();
                if (fieldVal != '' && value != '') {
                    return (value == fieldVal);
                } else return false
            }
        });
        this.registerChecker();
        this.registerButtonActivator();
    }

    private areRegistrationFieldsValid(fields, confirmation, num_fields) {
        let count = 0;
        let goal = num_fields;
        fields.forEach((field) => {
            if (field.validate()) {
                field.closest('div.field').addClass('valid-field');
                count++;
            } else {
                field.closest('div.field').removeClass('valid-field');
            }
        });
        if (confirmation.validate(this.$password)) {
            confirmation.closest('div.field').addClass('valid-field');
            count++;
        } else {
            confirmation.closest('div.field').removeClass('valid-field');
        }
        if (count == goal) return true;
    }

    private passwordContainsNameOrEmail() {
      const value: string = this.$password.val().toString().toLowerCase();

      let passwordIncludesEmail;
      if (this.$email.val()) {
        const groups = this.emailValidator.exec(String(this.$email.val()));
        passwordIncludesEmail = groups && value.includes(groups[1].toLowerCase());
      }

      const passwordIncludesLastName = this.$lastName.val() && value.includes(String(this.$lastName.val()).toLowerCase());
      const passwordIncludesFirstName = this.$firstName.val() && value.includes(String(this.$firstName.val()).toLowerCase());

      return passwordIncludesEmail || passwordIncludesFirstName || passwordIncludesLastName;
    }

    private registerButtonActivator() {
      const fields = [this.$firstName, this.$lastName, this.$email, this.$password, this.$password_confirmation];

      fields.forEach(($obj) => {
        if ($obj.length) {
          let numFieldsToCheck: number = 3;
          if (
            ($obj.closest('body').is('.passwords.edit, .passwords.update, .password_expired.show, .password_expired.update, .registrations.edit_password, .registrations.update')) ||
            ($obj.closest('form').is('#user_registration_form, #reset_password_form, #change_password_form'))
          ) {
            numFieldsToCheck = 2
          }
          $obj.on('input', (event) => {
            if (this.areRegistrationFieldsValid(this.registrationFields, this.$password_confirmation, numFieldsToCheck) && !this.passwordContainsNameOrEmail()) {
              this.$submitButton.prop('disabled', false);
            } else {
              this.$submitButton.attr('disabled', 'true');
            }
          });
        }
      });
    }

    registerChecker() {
        [this.$password, this.$email, this.$firstName, this.$lastName].forEach(el => {
            el.on('input', () => {
                this.toggleMessage()
            });
        });
    }

    toggleMessage() {
        const value: string = this.$password.val().toString();
        if (value.length) {
            if (this.caseValidator.test(value)) {
                this.turnOnCheck(this.$caseMessage)
            } else {
                this.turnOffCheck(this.$caseMessage)
            }

            if (this.numberValidator.test(value)) {
              this.turnOnCheck(this.$numberMessage)
            } else {
              this.turnOffCheck(this.$numberMessage)
            }

            if (value.length >= this.passwordMin) {
                this.turnOnCheck(this.$lengthMessage);

                if (this.passwordContainsNameOrEmail()) {
                  this.turnOffCheck(this.$noEmailMessage);
                } else {
                  this.turnOnCheck(this.$noEmailMessage);
                }
            } else {
                this.turnOffCheck(this.$lengthMessage);
                this.turnOffCheck(this.$noEmailMessage);
            }
        } else {
            this.turnOffCheck(this.$caseMessage);
            this.turnOffCheck(this.$numberMessage);
            this.turnOffCheck(this.$noEmailMessage);
            this.turnOffCheck(this.$lengthMessage);
        }
    }

    private turnOnCheck(message) {
        message.addClass('valid');
        message.find('span').addClass('circle-check green');
        message.find('span').removeClass('circle gray')
    }

    private turnOffCheck(message) {
        message.removeClass('valid');
        message.find('span').addClass('circle gray');
        message.find('span').removeClass('circle-check green')
    }
}


window.addEventListener("DOMContentLoaded", () =>{
  const registrationModalBody = document.querySelector('#sign_modal .modal-body')
  let modalObserver = new RegistrationModalObserver()
  if(registrationModalBody !== null) {
    modalObserver.init(registrationModalBody)
  } else {
    const passwordValidator = new PasswordValidator();
    passwordValidator.init();
  }
});


class RegistrationModalObserver {
  observer;
  observerOptions = { attributes: false, childList: true, subtree: false };
  callback = function(mutationsList, observer) {
    function hasPasswordField(node: Node): boolean {
      return node.nodeType === 1 && (node.querySelector('[type=password]') != null);
    }
    for(const mutation of mutationsList) {
      let form = Array.from(mutation.addedNodes).find((node: Node) => hasPasswordField(node));
      if (form !== undefined) {
        new PasswordValidator().init();
      }
    }
  };
  init(targetNode) {
    this.observer = new MutationObserver(this.callback);
    this.observer.observe(targetNode, this.observerOptions);
  }
  disconnect() {
    return this.observer && this.observer.disconnect();
  }
}




