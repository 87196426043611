<!-- NOTICE:
Any changes made to this file must also be made to the notification-monitor.vue file in formability packs
-->
<template>
  <li class="nav-item dropdown">
    <button class="dropdown-toggle notification-dropdown" id="notification-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <div class="notification-box" data-c-tooltip="Claim Records">
        <icon :class="notificationClasses"></icon>
        <span class="notifications-count">{{ unseenNotifications.length }}</span>
      </div>
    </button>
    <ul v-if="unseenNotifications && unseenNotifications.length" class="dropdown-menu notification-dropdown dropdown-menu-right" aria-labelledby="notification-dropdown">

      <li v-for="notification in unseenNotifications"
          :key="keyFor(notification)"
          class="dropdown-item">
        <button class="dropdown-option" @click="markAndNavigate(notification)">
          <p class="dropdown-option-header">{{ notification.message }}</p>
          <p class="dropdown-option-text">{{ notification.sub_message }}</p>
        </button>
      </li>
    </ul>
  </li>
</template>

<script lang="ts">
  import Vue from "vue";
  import {Hash} from "../../common/interfaces/hashInterfaces";
  import {NotificationManager} from "./models/notificationManager"

  export default Vue.extend({
    data(){
      return {
        notificationManager: new NotificationManager(),
      }
    },
    mounted() {
      $(document).on("entityRecordClaimed", () => {
        this.notificationManager.init();
      })
    },
    beforeDestroy() {
      $(document).off("entityRecordClaimed")
    },
    methods: {
      keyFor(notification): string {
        const idKey = this.notificationManager.notificationTypes[notification.type].idKey;
        return notification[idKey]
      },
      markAndNavigate(notification): void {
        // this.notificationManager.markAsSeen(notification);
        window.location.assign("/entities/claim")
      },
    },
    computed: {
      unseenNotifications(): Array<Hash>{
        return this.notifications.filter(n => !n.isSeen)
      },
      notifications(): Array<Hash> {
        return this.notificationManager.collection
      },
      notificationClasses() {
        return [
          this.unseenNotifications.length > 0 ? "notification-on" : "notification-off"
        ];
      }
    }
  })
</script>