// TODO: link functionality is not done yet, it required the container to have pointer events

let tooltipDB = {
  // to use it just set the --data-key-tooltip="keyExample"-- into the html tag
  /* -------------- guide:begin -------------- */
  keyExample: {
    title: 'Heading, same size as paragraphs but bold',
    content: [
      {
        type: 'p',
        text: 'Recusandae accusamus fuga maiores blanditiis laborum molestias et possimus. Atque tempora ipsa maxime.'
      },
      {
        type: 'p',
        text: 'Laborum molestias et possimus. Atque tempora ipsa maxime.'
      },
      {
        type: 'strong',
        text: 'UL List Title'
      },
      {
        type: 'ul',
        text: [
          'element 03',
          'element 04'
        ]
      },
      {
        type: 'strong',
        text: 'OL List Title'
      },
      {
        type: 'ol',
        text: [
          'element 05',
          'element 06'
        ]
      },
      {
        type: 'em',
        text: 'Italic text'
      },
      {
        type: 'a',
        text: 'www.formability.com',
        href: '#'
      }
    ]
  },
  /* -------------- guide:end -------------- */
};

export {tooltipDB};