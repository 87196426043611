// NOTICE:
// Any changes made to this file must also be made to the notificationManager.ts file in formability packs

import {Hash} from "../../../common/interfaces/hashInterfaces";
import Vue from "vue";

enum NotificationType {
  unclaimedRecord = "unclaimed_record"
}

export class NotificationManager {
  collection: Array<Hash> = [];

  notificationTypes: {[key: string]: {type: NotificationType, idKey: string}} = {
    unclaimed_record: {
      type: NotificationType.unclaimedRecord,
      idKey: "unclaimed_record_id"
    }
  };

  constructor() {
    this.init();
  }

  findOrInitLocalNotifications(): Array<Hash> {
    let localNotifications: string = localStorage.getItem('notifications');
    if (["undefined", null, undefined].includes(localNotifications)) {
      localStorage.setItem('notifications', JSON.stringify([]));
    }
    return JSON.parse(localStorage.getItem('notifications'));
  }

  reconcileNotifications(serverNotifications, localNotifications): void {
    const keepNotifications = [];
    Object.keys(this.notificationTypes).forEach(key => {
      let {type, idKey} = this.notificationTypes[key];

      localNotifications.filter(n => n.type === type).forEach(ln => {
        const target = serverNotifications.find(sn => {
          return (sn[idKey] === ln[idKey]);
        });
        if (target) {
          delete target.isSeen;
          keepNotifications.push(Object.assign(ln, target))
        }
      });

      serverNotifications.forEach(n => {
        const target = keepNotifications.find(kn => {
          return (kn[idKey] === n[idKey]);
        });
        if (!target) keepNotifications.push(Object.assign({}, n))
      })
    });
    Vue.set(this, "collection", keepNotifications.slice());
  }

  async init() {
    const serverNotifications = await this.getNotifications();
    const localNotifications = this.findOrInitLocalNotifications();
    this.reconcileNotifications(serverNotifications, localNotifications);
    this.syncNotifications();
  }

  markAsSeen(notification) {
    notification.isSeen = true;
    this.syncNotifications()
  }

  async getNotifications() {
    const res = await $.ajax({
      method: "GET",
      url: "/notifications",
      headers: {
        Accepts: "application/json"
      }
    });
    const {notifications} = res;
    return notifications;
  }

  syncNotifications(): void {
    localStorage.setItem("notifications", JSON.stringify(this.collection.slice()))
  }
}

