import {tooltipDB} from './cTooltipDictionary'

export default class cTooltip {
  $doc: any = document;
  $window: any = window;

  /* ------------------------------------------ */

  init($parentElement = $(document)) {
    let nodeTarget = $parentElement.find('[data-c-tooltip]');
    nodeTarget.hover(this.createTooltipContainer.bind(this), this.deleteTooltipContainer.bind(this));

    let nodeKeyTarget = $parentElement.find('[data-key-tooltip]');
    nodeKeyTarget.hover(this.createKeyTooltipContainer.bind(this), this.deleteTooltipContainer.bind(this));
  }

  /**
   *
   * @param $parentElement The parent of the element containing attribute data-c-tooltip-on-trigger
   * @param hideAfter Number of milliseconds after which the tooltip will be hidden
   * @description For when you want to trigger a tooltip on demand, rather than hover
   */
  show($parentElement, hideAfter: number): void {
    let $nodeTarget = $parentElement.find('[data-c-tooltip-on-trigger]');
    this.createTooltipContainer($nodeTarget);
    setTimeout(this.deleteTooltipContainer.bind(this), hideAfter)
  }

  createNode(obj) {
    let node = this.$doc.createElement(obj.type);
    let text = this.$doc.createTextNode(obj.text);
    let hasAttributes = obj.attributes != "";

    node.appendChild(text);

    if (hasAttributes) {
      for (let currentAttribute in obj.attributes) {
        let attr = this.$doc.createAttribute(obj.attributes[currentAttribute].name);
        attr.value = obj.attributes[currentAttribute].value;
        node.setAttributeNode(attr);
      }
    }

    obj.parent.appendChild(node);
    return node;
  }

  setTooltipPosition(event) {
    // window values
    let winHeight = this.$window.innerHeight;
    let winWidth = this.$window.innerWidth;

    // tooltip container
    let tooltip = this.$doc.getElementById('tooltip-container');
    let tooltipWidth = tooltip.offsetWidth;
    let tooltipHeight = tooltip.offsetHeight;

    // hovering element currently target
    let target = event?.currentTarget || event[0];
    let targetLeft = target.getBoundingClientRect().left;
    let targetTop = target.getBoundingClientRect().top;
    let targetWidth = target.offsetWidth;
    let targetHeight = target.offsetHeight;

    // adjusting the position for style purposes
    let leftAdjustment = 15;
    let topAdjustment = 0;
    let bottomAdjustment = 25;

    let tooltipRightCorner = targetLeft + targetWidth + tooltipWidth + leftAdjustment;
    let tooltipBottomCorner = targetTop + targetHeight + tooltipHeight + topAdjustment;

    let tooltipLeft = targetLeft + targetWidth + leftAdjustment;
    let tooltipTop = targetTop + topAdjustment;

    let isOnTopEdge = tooltip.classList.contains('force-top');
    let isOnRightEdge = tooltipRightCorner > winWidth;
    let isOnBottomEdge = tooltipBottomCorner > winHeight;

    if (isOnRightEdge) {
      tooltipLeft = targetLeft - tooltipWidth - leftAdjustment;
      tooltip.className += " left"
    }
    
    if (isOnBottomEdge) {
      tooltipTop = targetTop - tooltipHeight + bottomAdjustment;
      tooltip.className += " bottom"
    }

    if (isOnTopEdge) {
      tooltipLeft = targetLeft - tooltipWidth / 2 + targetWidth / 2;
      tooltipTop = targetTop - tooltipHeight - 5;
    }

    tooltip.style.top = tooltipTop + 'px';
    tooltip.style.left = tooltipLeft + 'px';
  }

  getTooltipText(event, selector) {
    let target = event.currentTarget;
    return target.getAttribute(selector);
  }

  createTooltipContainer(eventOrJQuery) {
    let customClass: string;
    let nodeTarget: HTMLElement;
    if (eventOrJQuery[0] instanceof HTMLElement) {
      nodeTarget = eventOrJQuery[0]
      customClass = nodeTarget.getAttribute('data-c-tooltip-class');
    } else {
      customClass = eventOrJQuery.currentTarget.getAttribute('data-c-tooltip-class');
    }

    let containerParams = {
      text: '',
      type: 'aside',
      parent: this.$doc.getElementsByTagName('body')[0],
      attributes: [
        {name: 'id', value: 'tooltip-container'},
        {name: 'class', value: customClass}
      ]
    };

    let tooltipText: string;
    if (nodeTarget) {
      tooltipText = nodeTarget.getAttribute('data-c-tooltip-on-trigger');
    } else {
      tooltipText = this.getTooltipText(event, 'data-c-tooltip');
    }

    let isEmpty = tooltipText == "";

    if(!isEmpty){
      let containerNode = this.createNode(containerParams);

      let contentParams = {
        text: tooltipText,
        type: 'p',
        parent: containerNode
      };

      this.createNode(contentParams);

      this.setTooltipPosition(eventOrJQuery);
    }
  }

  deleteTooltipContainer() {
    let tooltipContainer = this.$doc.getElementById('tooltip-container');
    if(tooltipContainer){
      tooltipContainer.remove();
    }
  }

  /* -------------------- key implementation */

  createKeyTooltipContainer(event) {

    let key = this.getTooltipText(event, 'data-key-tooltip');
    let hasKey = tooltipDB[key] !== undefined;

    if(hasKey){
      let containerParams = {
        text: '',
        type: 'aside',
        parent: this.$doc.getElementsByTagName('body')[0],
        attributes: [
          {name: 'id', value: 'tooltip-container'},
          {name: 'class', value: 'key'}
        ]
      };
      let containerNode = this.createNode(containerParams);

      let hasTitle = tooltipDB[key].title != '';

      if(hasTitle){
        let titleParams = {
          text: tooltipDB[key].title,
          type: 'h6',
          parent: containerNode,
        };
        this.createNode(titleParams);
      }

      tooltipDB[key].content.forEach((newNode) => {
        let newContainer = containerNode;

        let hasParagraph = ['strong', 'em'].includes(newNode.type);
        let isListing = ['ol', 'ul'].includes(newNode.type);
        let isLink = newNode.type == 'a';

        if(hasParagraph) {
          let newParagraphParams = {
            text: '',
            type: 'p',
            parent: newContainer
          };
          newContainer = this.createNode(newParagraphParams);

          let contentParams = {
            text: newNode.text,
            type: newNode.type,
            parent: newContainer
          };
          this.createNode(contentParams);

        } else if(isLink) {
          let newParagraphParams = {
            text: '',
            type: 'p',
            parent: newContainer,
          };
          newContainer = this.createNode(newParagraphParams);

          let contentParams = {
            text: newNode.text,
            type: newNode.type,
            parent: newContainer,
            attributes: [
              {name: 'href', value: newNode.url},
            ]
          };
          this.createNode(contentParams);

        } else if(isListing) {
          let newListParams = {
            text: '',
            type: newNode.type,
            parent: newContainer
          };
          newContainer = this.createNode(newListParams);

          newNode.text.forEach((newLiNode) => {
            let contentParams = {
              text: newLiNode,
              type: 'li',
              parent: newContainer
            };
            this.createNode(contentParams);
          });

        } else {
          let contentParams = {
            text: newNode.text,
            type: newNode.type,
            parent: newContainer
          };
          this.createNode(contentParams);
        }

        this.setTooltipPosition(event);
      });
    }
  }
}

let tooltip = new cTooltip();
$(window).on('load', () => tooltip.init());

