function StickyFooter() {
  let $client = window;
  let $doc = $(document);
  let $mainContainer = $doc.find('main');

  let $navbar = $doc.find('body > nav.navbar');
  // let $header = $doc.find('body > main > header');
  let $footer = $doc.find('body > footer');

  let clientHeight = $client.innerHeight;
  // let mainContainerHeight = parseInt($mainContainer.css('height'));
  let mainContainerHeight = $mainContainer.height();
  let navbarHeight = parseInt($navbar.css('height')) || 0;
  // let headerHeight = parseInt($header.css('height')) || 0;
  // let headerMargin = parseInt($header.css('margin-bottom')) || 0;
  let footerHeight = $footer.innerHeight() || 0;

  let boxWtMargin: JQuery<Document> = $mainContainer.find('.paperbox, section.container');
  let ctaAvailable = $mainContainer.find('.cta.navigation').length;
  let numberOfBoxes = boxWtMargin.length;
  let boxesMargin = 0;

  if(numberOfBoxes && !ctaAvailable) {
    boxesMargin = parseInt(boxWtMargin.css('margin-bottom'));
  }

  let marginFix = 0;

  let isScreenBiggerThanContent = clientHeight > mainContainerHeight;

  if (isScreenBiggerThanContent) {
    $mainContainer.css({ 'min-height': clientHeight - (navbarHeight + footerHeight + marginFix + boxesMargin) });
  }
}

function init(recalculateFooter) {
  StickyFooter();

  if(recalculateFooter){
    setTimeout(function(){
      StickyFooter();
    }, 100);
  }
}

/* List of buttons/inputs that needs to run the sticky function after they are triggered */
let revealInputs = [].join(',');


$(window).on('scroll resize load', () => {
  StickyFooter();
});

const observer = new MutationObserver(StickyFooter);


$(window).on('load', () => {
  observer.disconnect();
  let targetNode = document.getElementsByTagName('body')[0];
  let config = { attributes: false, childList: true, subtree: true };
  observer.observe(targetNode, config);
  init(true);

  $(revealInputs).on('click', () => {
    init(true);
  });
});