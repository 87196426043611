
<li class="nav-item dropdown">
  <button class="dropdown-toggle notification-dropdown" id="notification-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <div class="notification-box" data-c-tooltip="Claim Records">
      <icon :class="notificationClasses"></icon>
      <span class="notifications-count">{{ unseenNotifications.length }}</span>
    </div>
  </button>
  <ul v-if="unseenNotifications && unseenNotifications.length" class="dropdown-menu notification-dropdown dropdown-menu-right" aria-labelledby="notification-dropdown">

    <li v-for="notification in unseenNotifications"
        :key="keyFor(notification)"
        class="dropdown-item">
      <button class="dropdown-option" @click="markAndNavigate(notification)">
        <p class="dropdown-option-header">{{ notification.message }}</p>
        <p class="dropdown-option-text">{{ notification.sub_message }}</p>
      </button>
    </li>
  </ul>
</li>
