import CTooltip from "./common/cTooltip";

class PillNav {
  static navs: PillNav[] = [];
  $pillContainer: JQuery;
  allPillData: Array<{id: string, pillText: string, name: string, path: string}>;
  $stubPill: JQuery = $(`<a href="/" class="btn nav-pill stub-pill">&#8205;</a>`);
  showAll: boolean = false;
  hasMoreLess: boolean = false;
  hiddenPillDiv: JQuery;
  tooltip: CTooltip;
  _showMoreEl: JQuery;
  _showLessEl: JQuery;

  constructor(pillContainer: JQuery) {
    this.tooltip = new CTooltip();
    this.$pillContainer = pillContainer;
    this.allPillData = this.$pillContainer.data("pill-data");
    this.setShowLessEl();
    this.setShowMoreEl();
    this.showPills();
  }

  static init() {
    const $pillNavs = $("[data-pill-data]");
    $pillNavs.each((ind, el) => {
      const $el = $(el);
      if ($el.data('pill-data').length) {
        PillNav.navs.push(new PillNav($(el)))
      }
    });

  }

  showPills() {
    let currentTop = 0;
    let tmpTop;
    let level = 0;
    const maxLevel = 1;
    this.allPillData.forEach((pill) => {
      if (!this.showAll) {
        this.$pillContainer.append(this.$stubPill);
        tmpTop = this.$stubPill.offset().top;
        if (tmpTop > currentTop) {
          currentTop = tmpTop;
          level++;
        }
        this.$stubPill.remove();
        if (level > maxLevel) {
          this.hasMoreLess = true;
          return;
        }

      }
      this.$pillContainer.append(this.createElement(pill));
    });
    this.hideShow();
    this.tooltip.init();
  }

  reset() {
    this.$pillContainer.empty();
  }

  hideShow() {
    if (!this.hasMoreLess) {
      this.showLessEl.hide();
      this.showMoreEl.hide();
    } else {
      if (this.showAll) {
        this.showLessEl.show();
        this.showMoreEl.hide();
      } else {
        this.showLessEl.hide();
        this.showMoreEl.show();
      }
    }
  }

  createElement(pill){
    const tooltip = pill.name.length > 30 ? pill.name : null;
    const newEl = $(`<a href="${pill.path}" class="btn nav-pill">${pill.pillText}</a>`);
    if (tooltip) {
      newEl.attr("data-c-tooltip", tooltip);
    }
    return newEl;
  }

  get showMoreEl() {
    if (!this._showMoreEl) {
      this.setShowMoreEl();
    }
    return this._showMoreEl;
  }

  setShowMoreEl() {
    this._showMoreEl = $(`<div class="row align-right"><div class="col-12"><button type="button" class="btn-link show-more-pills">Show More...</button></div></div>`);
    this._showMoreEl.find("button").on("click", (e) => {
      this.showAll = true;
      this.reset();
      this.showPills();
    });
    this.$pillContainer.after(this._showMoreEl);
  }

  get showLessEl() {
    if (!this._showLessEl) {
      this.setShowLessEl();
    }
    return this._showLessEl;
  }

  setShowLessEl() {
    this._showLessEl = $(`<div class="row align-right"><div class="col-12"><button type="button" class="btn-link show-less-pills">Show Less...</button></div></div>`);
    this._showLessEl.find("button").on("click", (e) => {
      this.showAll = false;
      this.reset();
      this.showPills();
    });
    this.$pillContainer.after(this._showLessEl);
  }

}

window.addEventListener("DOMContentLoaded", PillNav.init);





