// NOTICE:
// Any changes made to this file must also be made to the notificationMonitor.ts file in formability packs

import Vue from "vue";
import "../../common/vue/common";
import NotificationMonitor from "./notification-monitor.vue"

const onReady = function () {
  const $rootElements = $("div[data-vue-notification-monitor]");

  $rootElements.each((ind, el) => {
    new Vue({
      el: el,
      render: h => h(NotificationMonitor),
    })
  });
};

$(onReady);